import React, { useState, createContext ,useContext, useEffect} from "react";





// Create a new context
const defaultContext = {
        actionError:'',
        isLoggedIn:false,
        isAudioOpen: false,
        isMuted:false,
        selectedTopping:''  
};
export const AppContext = createContext(defaultContext);

// Create a context provider component
export const AppProvider = ({ children }) => {
    const [oauthUser, setOauthUser ] = useState(null)
    const [actionError, setActionError ] = useState(null)
    const [actionText, setActionText ] = useState('')
    const [ geoData, setGeoData ] = useState(false)
    const [ appData, setAppData ] = useState(false)
    const [ userData, setUserData ] = useState()
    const [ navValue, setNavValue ] = useState(null)
    const [ isAudioOpen,setIsAudioOpen ] = useState(true)
    const [ isFullView, setIsFullView ] = useState(false)
    const [ fullSrc, setIsFullSrc ] = useState('')
    const [ infoOpen, setInfoOpen ] = useState(false)
    const [ menuState, setMenuState] = useState(false)
    const [ isMuted, setIsMuted ] = useState(false)
    const [ appTheme, setAppTheme ] = useState({})
    const [isLoggedIn, setIsLoggedIn] = useState(false)
  

  const updateAppData = (newData) => {
    setAppData((prevState) => ({
        appTheme,
        userData,
        navValue,
        menuState,
        isAudioOpen,
         fullSrc,
         isFullView,
        geoData,
        oauthUser,
        actionError,
        actionText,
        infoOpen, 
        isAudioOpen,
        isMuted, 
        ...userData,
        ...prevState,
        ...newData,   
    }));
    
  };
  

  return (
    <AppContext.Provider value={{ 
        appData, 
        updateAppData,
        appTheme, 
        setAppTheme,
        navValue, 
        setNavValue,
        menuState, 
        setMenuState,
        isAudioOpen, 
        isFullView, 
        setIsFullView,
        fullSrc, 
        setIsFullSrc, 
        userData, 
        setUserData,
        geoData,
        setGeoData,
        actionError, 
        setActionError,
        actionText, 
        setActionText, 
        oauthUser, 
        setOauthUser, 
        isLoggedIn,
        setIsLoggedIn,
        infoOpen, 
        setInfoOpen, 
        isAudioOpen, 
        setIsAudioOpen,
        isMuted, 
        setIsMuted,
      
        }}>
      {children}
    </AppContext.Provider>
  );
};
export const AppState = () => {
  return useContext(AppContext);
};