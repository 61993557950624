import { Box, Button, Container, Input, TextField, Typography } from '@mui/material'
import React, { useEffect ,useContext} from 'react'
import { AppContext } from '../context/AppProvider';

import * as vars from '../utils/Vars'
const ContactContent = (props) => {
const { magnifiedArea,setMagnifiedArea,updateMagnifiedArea,imageSrc, setImageSrc, isViewAble, setIsViewAble,infoOpen, setInfoOpen } = useContext(AppContext)

    useEffect(()=>{
        // setInfoOpen(true)
    },[])

  return (
    <Container style={{position:'relative',display: 'flex',
    flexDirection:'column',color:'#fff',
    zIndex:99999,width:'100%',maxWidth:'700px', height:'100%'
    // border:'3px solid #000',borderRadius:'8px 8px'
    }}>
        <div style={{position:'relative',width:"100%", height:'1.25rem',
                    backgroundColor: vars.colors.term_header, 
                    color: vars.colors.term_headerText,
                    borderRadius:'5px 5px 0px 0px',
                    textAlign:'center',
                    alignItems:'center',
                   zIndex:99999
                    }}>
                        <div className="term-button-cls" id="term-button-cls"onClick={()=>{
                                 props.toggleDrawer(!props.var)
                        }}>
                                <span id="x-btn-text">x</span></div>
                        <Typography sx={{fontSize:'0.8rem',fontWeight:'bold'}}>
                        - Contact
                        </Typography>
                        </div>
        <Container sx={{position:'relative',display: 'flex',flexDirection:'column',color:'#000', 
            boxShadow:'0px 0px 5px 2px rgba(0,0,0,0.4),inset 0px 0px 10px 5px rgba(0,0,0,0.4)',
            minWidth:'100%', justifyContent:'center', margin:'auto', backgroundColor:'#fff', 
            padding:'0.5rem',
            backgroundImage: `url("/assets/StripGrad_BlueBG.png")`,
            backgroundSize: "contain",overflow:'hidden'}}>
            <form 
                action="https://getform.io/f/e27dbca2-a468-42e9-a045-b92ce997c29b" 
                method="POST"
                enctype="multipart/form-data"
                name="EmailForm" target="_blank"
                style={{display: 'flex',flexDirection:'column', gap:'0.5rem', fontSize:'0.75rem'}}
                > 
{/* 
                    <div style={{display: 'flex',flexDirection:'column',borderRadius:'5px'}}>
                        <label for="name">Name</label>  */}
                        <TextField type="text" size='small'  label='Name' name="Contact-Name" id='name' sx={{backgroundColor:'#FFF'}}required />
                    {/* </div> */}

                    {/* <div style={{display: 'flex',flexDirection:'column',borderRadius:'5px'}}>
                        <label for="email">Email</label>  */}
                        <TextField type="email" size='small'  label='Email' name="Contact-Email" sx={{backgroundColor:'#FFF'}} required/>
                    {/* </div >
                    <div style={{display: 'flex',flexDirection:'column',borderRadius:'5px'}}> */}
                        {/* <label for="phone">Phone</label>  */}
                        <TextField type="phone" size='small' label='Phone' name="Contact-Phone" sx={{backgroundColor:'#FFF'}} required/>
                     {/* </div > */}
                    
                    <div style={{display: 'flex',flexDirection:'column'}}> 
                        {/* <label for="Message">Message</label>  */}
                        {/* <TextField name="Contact-Message" rows='6' cols="20" label='Message'
                        style={{maxWidth:"100%",minWidth:"95%", margin: '1rem auto',borderRadius:'5px',
                        }} required/> */}
                        <textarea name="Contact-Message" rows='6' cols="20" placeholder='Message *'
                        style={{maxWidth:"100%",minWidth:"90%", margin: 'auto',borderRadius:'5px',padding:'0.5rem'}} required/>
                    </div>
                
                
                    <Button type="submit" value="Submit" className='hover-shake'
                         style={{width:'60%', margin: 'auto', backgroundColor:'#FFF',cursor:'pointer',
                        border:'2px solid #000', borderRadius:'5px'}} //onClick="toggleContact()"
                    
                    >Send
                    </Button>
            </form>
        </Container>
    </Container>
  )
}

export default ContactContent
