import { Box, Container, Typography } from '@mui/material'
import React from 'react'
import * as vars from '../utils/Vars'

const DevToolsWindow = (props) => {
  const navValue = props.navValue
   const p = props.project
// const toggleMenu = props.toggleMenu()

  return (
   
    <Box sx={{position:'relative',display:'flex',flexDirection:'column',width:'95%', 
    maxWidth: '600px',height:'100%',borderRadius:'5px ',margin:'auto',backgroundColor:'#fff',
    p:0 ,borderRadius:'5px 5px 0 0 ',boxShadow:'0px 2px 10px 5px rgba(0,0,0,0.4 )'}}>
    {/* <Box sx={{position:'relative',display:'flex',flexDirection:'column',width:'100%', maxWidth: '600px',height:'100%',
    p:0 ,borderRadius:'5px 5px 0 0 ',boxShadow:'0px 2px 10px 5px rgba(0,0,0,0.4 )'}}> */}
                <Box sx={{position:'relative',display:'flex', height: '1rem', width:'100%',
                    backgroundColor: vars.colors.term_header,borderRadius:'5px 5px 0 0',
                    justifyContent:'center',p:0,border:'1px solid rgba(134, 134, 134, 0.3)',
                    borderLeft:0, borderRight:0,
                    padding:0,m:0}}>

        <div className="term-button-cls"style={{position: 'absolute'}} id="term-button-cls"onClick={()=>{props.toggleInfo()}}>
          <span className="" id="x-btn-text">x</span></div>
                    <Typography sx={{position:'relative', fontSize:'0.75rem', color: vars.colors.term_headerText,fontWeight:'bold',}}>
                DevTools - {p?.name}
            </Typography>
                </Box>

       {(navValue !==4 && navValue !==null ) && <Box sx={{ display:'flex', flexDirection:'column',height:'100%',position:'relative', gap:'0.25rem', overflow:'scroll', fontSize:'0.7rem',padding:'0.5rem',}}>
        <span style={{position:'absolute', top:'0', right:'0.5rem'}}> <b>Status: </b> <span style={{}}>{p?.status?p?.status:'...'}</span> </span>
        <span> <b>Year: </b> {p?.year?p?.year.split('.',1):'...'}</span>
        <span>  <b>Type: </b> {p?.type ?p?.type:'...'}</span>
        <span>  <b>Technologies: </b> {p?.technologies? p?.technologies.map((t,i)=> i !== 0? ', '+t: t):'...'}</span>
            <span> <b>Description: </b> {p?.description? p?.description:'...'}</span>
            <span> <b>Inspiration: </b> {p?.inspiration? p?.inspiration:'...'}</span>
            <span> <b>Implementation: </b> {p?.info?.implementation.length >0 ? p?.info?.implementation?.map((e,i)=> <p key={i}>{p?.info?.implementation?.length > 0 ? <b>&#x2022; &nbsp;</b>:''}{e}<br></br></p>):'...'}</span>
        </Box>}
        {navValue === null && <div style={{margin:'auto', display:'flex', flexDirection:'column', overflow:'scroll',textAlign:'center'}}>
               <h1 style={{marginBottom:0}}>Glo&#x2022;aun</h1>
              <p style={{marginTop:0}}>(Glow On)</p>
              </div>}
        {navValue === 4 && <div style={{margin:'auto', display:'flex', flexDirection:'column', overflow:'scroll',textAlign:'center'}}>
               <h2 style={{marginBottom:0}}>Greetings!</h2>
              <p style={{marginTop:0}}>Interested? Drop a line!</p>
              </div>}
    </Box>
    // </Box>
  )
}

export default DevToolsWindow