import React,{useEffect} from 'react'
import BrowserPojects from './BrowserPojects'
import BrowserMusic from './BrowserMusic'

const BrowserContent = (props) => {
    const view = props.view
    const project = props.project
    useEffect(()=>{
        // console.log("BrowserContent:",project)
    },[project,view])
  return (
    <>
       {view === 'projects' && <BrowserPojects project={project}/>}
        {view === 'music' && <BrowserMusic project={project}/>}
    </>
  )
}

export default BrowserContent