import { Box, Container, Typography } from '@mui/material'
import React,{useEffect, useState, useRef, useContext} from 'react'
import AppleIcon from '@mui/icons-material/Apple';
import { BatteryCharging90, Wifi, Wifi2Bar } from '@mui/icons-material';

import * as vars from '../utils/Vars'
import { MagnifierContext } from '../context/MagnifierProvider';
import { AppContext } from '../context/AppProvider';

import useWindowDimensions from "../hooks/windowDimensions";

import closeIcon from '../assets/close-x.svg'
import ContactContent from './ContactContent';


const ResumeComponent = (props) => {
    const { magnifiedArea,setMagnifiedArea,updateMagnifiedArea,imageSrc, 
        setImageSrc, isViewAble, setIsViewAble } = useContext(MagnifierContext)

         const { isAudioOpen,setIsAudioOpen,fullSrc, setIsFullSrc, navValue, setNavValue,
           menuState, setMenuState ,isFullView, setIsFullView } = useContext(AppContext);
    const [ blink, setBlink ] = useState(false)
    const [ isContact, setIsContact ] = useState(true)
    const [ showFileMenu, setShowFileMenu] = useState(false)
    const { height, width } = useWindowDimensions();

    // const [magnifiedArea, setMagnifiedArea] = useState(null);
    // const [sourceImg, setSourceImg] = useState(null);
    let magnificationFactor = 5; // Magnification factor
    const closeStyles = {
        position: 'absolute',
        bottom: '0.5rem',
        left:'0.5rem',
        // top: isAudioOpen?'3.5rem': (!menuState && !isAudioOpen)?'0.4rem':( menuState && !isAudioOpen )?'1.25rem':'1rem',
        // right: isAudioOpen?'0.25rem': (!menuState && !isAudioOpen)?'0.5rem':( menuState && !isAudioOpen )?'7rem':'7rem',
        zIndex: 9999999999,
        height:'1rem', 
        width:'1rem', 
        // bottom: isAudioOpen?'7.5rem':'0.5rem', 
        // left: isAudioOpen?'25%':'55%', 
        // left:'55%', 
        color:'', 
        borderRadius:'20%',
        padding: '.5rem',
        boxShadow:'0px 0px 4px 2px rgba(0, 0, 0, 0.20)',
        alignItems:'center',
        justifyContent:'center', 
        cursor: 'pointer',
        backgroundColor: '#FFF',
        border: '3px solid #000',
       

}

 const tempHandleImageClick = (e) => {
    setIsFullSrc(e.target.src)
    setIsFullView(!isFullView)
  }

    const handleImageClick = (event) => {
      let on = !isViewAble
      // console.log('Resume click1:',on) 
      setIsViewAble(on)
      updateMagnifiedArea({...magnifiedArea,isViewAble:true})
      setImageSrc(event.target.src)
      
      // Get click coordinates
      const clickX = event.nativeEvent.offsetX;
      const clickY = event.nativeEvent.offsetY;

      // Define the area to magnify (adjust the values as needed)

      const magnifiedWidth = width; // Width of the magnified area
      const magnifiedHeight = height-400; // Height of the magnified area

      // Calculate the area to magnify
      const magnifiedX = Math.max(0, clickX - magnifiedWidth / 4);
      const magnifiedY = Math.max(0, clickY - magnifiedHeight / 4);

      setMagnifiedArea({
        x: magnifiedX,
        y: magnifiedY,
        width: magnifiedWidth,
        height: magnifiedHeight,
        imageSrc:event.target.src,
        isViewAble:true
      });
      console.log('Resume click2:',magnifiedArea) 

    };



  var date = new Date();
    var formatter = new Intl.DateTimeFormat([], {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
     });
    var time = formatter.format(date);
    useEffect(()=>{

    },[magnifiedArea,imageSrc,isViewAble,isFullView])
  

  return (
    
      <Box  sx={{ //backgroundImage: `url("/assets/BodyBG2.png")`,
                position:'relative',height:'100%',alignItems:'center',width:'100%',
                backgroundSize: "contain", paddingTop:'2rem',
                 overflow:'hidden', display: 'flex',flexDirection:'Column'}}>
      
         <div style={{
                position:'absolute',
                display:'flex',
                top:0,
                left:0,
                width:"100%", height:'1.25rem',

                backgroundColor: vars.colors.term_header, 
                color: vars.colors.term_headerText,
                borderRadius:'5px 5px 0px 0px',
                textAlign:'center',
                alignItems:'center',
                justifyContent: 'center',
              
                }}>
                {/* <div class="term-button-cls" id="term-button-cls"><span class="" id="x-btn-text">x</span></div> */}
                < AppleIcon sx={{position:'absolute',top:'0.125rem',left:'0.25rem', fontSize:'0.75rem'}}/>
                <Typography sx={{fontSize:'0.65rem',position:'absolute', left:'1.5rem'}}>
                 <b>{!props.isContact ? 'Preview':'Contact'}</b>
                  <span style={{cursor:'pointer'}} onClick={()=>setShowFileMenu(!showFileMenu)}>&nbsp; &nbsp;File  &nbsp; &nbsp;</span>
                </Typography>
                {/* <Typography sx={{fontSize:'0.85rem'}}>
                    Projects
                </Typography> */}
               <Typography sx={{display: 'flex',position: 'absolute',
                        right: '0.25rem', top:'0rem', fontSize:'0.75rem',
                        justifyContent: 'center', alignItems:'center', gap:1}}>
                            <BatteryCharging90 sx={{transform:'rotate(90deg)',fontSize:'1.25rem' }} />
                    {/* {!blink 
                        ? <BatteryChargingFull sx={{transform:'rotate(90deg)',fontSize:'1.25rem' }} />
                        :<BatteryCharging90 sx={{transform:'rotate(90deg)',fontSize:'1.25rem' }} />} */}
                   {blink ?<Wifi fontSize='0.85rem'/>:<Wifi2Bar fontSize='0.85rem' />}
                    {time}
                </Typography>
            </div>
          {props.isContact && <Box sx={{p: '2rem', width:'100%',marginBottom:'2rem'}}>
             <ContactContent toggleDrawer={props.toggleDrawer}  var={props.var}/>
          
          
          </Box>}
    {!props.isContact && (<Box sx={{p: '2rem', width:'100%'}}>

      <div className="resume-browser-container" id="browser" style={{width:'90%',backgroundColor:'#868686',
            boxShadow:'0px 0px 8px 5px rgba(0, 0, 0, 0.2), inset 0px 20px 5px 3px rgba(0, 0, 0, 0.2)'

        }} >
                <div className="browser-header" id="browser-header">
                    <div className="browser-button-cls" onClick={()=>{
                        props.toggleDrawer(!props.var)
                        props.setCurrentItem()
                        // props.setNavValue(4)
                    }}>
                        <span className="x-btn-text blink" id="x-btn-text">x</span>
                    </div>
                    <span className="blink"id="browser-drag-tag">&nbsp;</span> 
                    <img src="/assets/denim.png" alt="denim texture" id="denim"/>
                   <Typography sx={{position: 'absolute', fontSize:'0.75rem'}}>Resume.pdf</Typography>
                </div>
              
                <Box  sx={{ display:'flex',position:'relative',
                 width:'100%', margin:'auto', padding:'0.25rem',
                justifyContent:'center', cursor:'zoom-in', backgroundColor:'#868686', 
                 boxShadow:' 0px 0px 5px 2px rgba(0, 0, 0, 0.4)'
                }}>
            <Box component={'img'}
                name='img1'
                // onMouseEnter={() => setBlink(true)}
                // onMouseLeave={() => setBlink(false)}
                src={'/assets/Resume-2024-pg1.png'}
                sx={{position:'relative',top:'0',left:'0',//width:'100%',
                // backgroundImage: `url("/assets/Self-Portrait-Plain-NoGlasses.png")`,
                // backgroundAttachment:'fixed',
                // backgroundSize:'cover',
            // // backgroundSize: "contain",
                // height:'auto',
                width:'30%',
                flex:1,
                cursor:'zoom-in',
                // p:'0.25rem', 
                //  margin:'auto'
            }}
            draggable="false"
            onClick={tempHandleImageClick}
            onMouseDown={tempHandleImageClick}
            onDrag={tempHandleImageClick}
            onMouseUp={()=>{
              setIsViewAble(!isViewAble)
              updateMagnifiedArea({
                ...magnifiedArea,
                isViewAble:!isViewAble
               } )
            }}
            onContextMenu={(e)=>e.preventDefault()}
            >
            </Box>

            <Box component={'img'}
                name='img2'
                // onMouseEnter={() => setBlink(true)}
                // onMouseLeave={() => setBlink(false)}
                src={'/assets/Resume-2024-pg2.png'}
                sx={{position:'relative',top:'0',left:'0',//width:'100%',
                // backgroundImage: `url("/assets/Self-Portrait-Plain-NoGlasses.png")`,
                // backgroundAttachment:'fixed',
                // backgroundSize:'cover',
                // // backgroundSize: "contain",
                // height:'auto',
                width:'30%',
                flex:1,
                cursor:'zoom-in',
                // p:'0.25rem', 
                // margin:'auto'
                
            }}
            onClick={tempHandleImageClick}
            onDrag={tempHandleImageClick}
            onMouseUp={()=>{
              setIsViewAble(!isViewAble)
              updateMagnifiedArea({
                ...magnifiedArea,
                isViewAble:!isViewAble
               } )
            }}
                draggable="false"
              onContextMenu={(e)=>e.preventDefault()}
                >
            </Box>
       
        </Box>
         
    </div>
           {/* {isViewAble && <div className='hover-shake' style={closeStyles} onClick={(e)=>{
            setIsViewAble(!isViewAble)
            updateMagnifiedArea({isViewAble:!isViewAble})
            // updateAppData({...appData,isAudioOpen})
            }}>
              <img src={closeIcon} width='100%'/>
                {/* {!isAudioOpen && <img src={musicIcon} width='100%'/>} */}
            {/* </div>} */} 
    </Box>)}
  
        </Box>
   
  )
}

export default ResumeComponent