import React,  {useContext} from 'react'
import musicIcon from '../assets/music-solid.svg'
import closeIcon from '../assets/close-x.svg'
import { AppContext } from "../context/AppProvider";


const AudioPlayerToggle = () => {
    const {appData,updateAppData, isAudioOpen,setIsAudioOpen,navValue, setNavValue, menuState, setMenuState} = useContext(AppContext);

    const btnStyles = {
        position: 'relative',
        // position: 'absolute',
        // top: isAudioOpen?'0.5rem': (menuState && isAudioOpen)?'0rem':(menuState && isAudioOpen)?'5rem':'1rem',
        // right: isAudioOpen?'0.5rem': (menuState && isAudioOpen)?'1rem':(menuState && isAudioOpen)?'0.5rem':'7rem',
        // top: isAudioOpen?'3.5rem': (!menuState && !isAudioOpen)?'0.4rem':( menuState && !isAudioOpen )?'1.25rem':'1rem',
        // right: isAudioOpen?'0.25rem': (!menuState && !isAudioOpen)?'0.5rem':( menuState && !isAudioOpen )?'7rem':'7rem',
        zIndex: menuState & !isAudioOpen? 0: 9999,
        height:'1rem', 
        width:'1rem', 
        // bottom: isAudioOpen?'7.5rem':'0.5rem', 
        // left: isAudioOpen?'25%':'55%', 
        // left:'55%', 
        color:'', 
        borderRadius:'20%',
        padding: '.5rem',
        boxShadow:'0px 0px 4px 2px rgba(0, 0, 0, 0.20)',
        alignItems:'center',
        justifyContent:'center', 
        cursor: 'pointer',
        backgroundColor: '#FFF',
        border: '3px solid #000',
       

}

  return (
    <>
        <div className='hover-shake' style={btnStyles} onClick={(e)=>{
            setIsAudioOpen(!isAudioOpen)
            updateAppData({...appData,isAudioOpen})
        }}>
        {isAudioOpen && <img src={closeIcon} width='100%'/>}
        {!isAudioOpen && <img src={musicIcon} width='100%'/>}
        </div>
    </>
  )
}

export default AudioPlayerToggle