export const sizes ={
    AppMaxWidth:'',
    AppMinWidth:'',
    AppMaxHeight:'',
    AppMinHeight:'',

}

export const colors ={

        accent_color_1: 'rgba(0,0,0,1)',
        accent_color_2: 'rgba(255,255,255,1)',
        text_color_1: 'rgba(0,0,0,1)',
        box_shadow_01: 'rgba(0,0,0,0.4)',
        text_color_2: 'rgba(255,255,255,1)',
        term_green: "#00FF00",
        closeButton:'#f66969',
        term_header: 'rgb(246, 246, 246)',
        term_headerText: 'grey',
        projectTitle: 'magenta',
    }
export const fonts = {

        main_app: 'Gilroy',
        term_head:'',
        term_title:'',
        term_text:'Monaco',
        term_btn_cls:'futura bold'
    }
export     const navObj = {
        0: 'design',
        1: 'development',
        2: 'resume',
        3: 'contact'
    }