import React,{useState} from "react"

const iconURL = "../assets/infoButton.png"
const testTarget =''
function InfoButton() {

    return(
        
    <button className="infoButton" id="info" onClick={'#'} >
    i   
    {/* <img src={iconURL} height ="50px" width ="50px" > </img> */}
     </button>
    )
}
export default InfoButton
