import logo from './logo.svg';
import './App.css';
import InfoButton from './components/InfoBotton/InfoButton';
import Home from './components/Home';
import { Box } from '@mui/material';


function App() {
  return (
    <div className="App" style={{margin:'auto',position:'absolute',maxWidth:'100vw',
    maxHeight:'100vh', height:'100vh', backgroundImage: `url("/assets/BodyBG2.png")`,
    backgroundSize: "cover",backgroundRepeat:'no-repeat',fontFamily:'Gilroy',overflow:'hidden'}}>
    <Home />
    {/* <Box component={'img'} 
    // src='./assets/Origins_Expanded_Resize.jpg'
    // src='./assets/Origins-(Expanded)_002(BLK_MID).jpg'
    sx={{ position:'absolute', top:0, left:0, zIndex:-1, height:'100%',maxWidth:'100vw', width:'100%',
            backgroundAttachment:'fixed'}}
    >

    </Box> */}
    </div>
  );
}

 export default App;
