import React, { useState, createContext } from "react";

// Create a new context
export const MagnifierContext = createContext();

// Create a context provider component
export const MagnifierProvider = ({ children }) => {

    const [ magnifiedArea  , setMagnifiedArea ] = useState(null)
    const [ imageSrc, setImageSrc] = useState('')
    const [ isViewAble, setIsViewAble ] = useState(false)

    const  updateMagnifiedArea = (newData) => {
        setMagnifiedArea((prevState) => ({
            magnifiedArea,
            imageSrc,
            isViewAble,
        }));
    
    };


    return (
        <MagnifierContext.Provider value={{ 
            magnifiedArea, 
            setMagnifiedArea,
            updateMagnifiedArea, 
            imageSrc, 
            setImageSrc,
             isViewAble, 
             setIsViewAble 
            }}>
                
        {children}
        </MagnifierContext.Provider>
    );
};
