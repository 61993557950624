import React,{useEffect, useState, useRef, useContext} from 'react'
import { AppContext } from '../context/AppProvider';
import { Box, Container, Modal } from '@mui/material';
import { styled, css } from '@mui/system';

import closeIcon from '../assets/close-x.svg'

const FullViewComponent = (props) => {
  const [open, setOpen] = React.useState(true);
    const {isFullView, setIsFullView ,fullSrc, setIsFullSrc } = useContext(AppContext)
    const [ modalContent, setModalContent ] = useState(props.imgUrl)
    const handleOpen = () => setOpen(true);
    const handleClose = () =>{ 
        setOpen(false);
        setIsFullSrc()
        setIsFullView(!isFullView)
    }
     const compStyles = {
        position: 'absolute',
        top: '1rem',
        right:'0.5rem',
        // top: isAudioOpen?'3.5rem': (!menuState && !isAudioOpen)?'0.4rem':( menuState && !isAudioOpen )?'1.25rem':'1rem',
        // right: isAudioOpen?'0.25rem': (!menuState && !isAudioOpen)?'0.5rem':( menuState && !isAudioOpen )?'7rem':'7rem',
        zIndex: isFullView?9999999999:0,
        display: isFullView?'':'none',
        visibility: isFullView?'visible':'hidden',
        height:'1rem', 
        width:'1rem', 
        // bottom: isAudioOpen?'7.5rem':'0.5rem', 
        // left: isAudioOpen?'25%':'55%', 
        // left:'55%', 
        color:'', 
        borderRadius:'20%',
        padding: '.5rem',
        boxShadow:'0px 0px 4px 2px rgba(0, 0, 0, 0.20)',
        alignItems:'center',
        justifyContent:'center', 
        cursor: 'pointer',
        backgroundColor: '#FFF',
        border: '3px solid #000',
       

}
useEffect(()=>{

},[fullSrc, isFullView])
// <div>
  {/* <TriggerButton type="button" onClick={handleOpen}>
    Open modal
  </TriggerButton> */}
  return (
      
      
      <Modal
      aria-labelledby="unstyled-modal-title"
      aria-describedby="unstyled-modal-description"
      open={open}
      onClose={handleClose}
      style={{zIndex: open?999999999:0,
        display: open?'flex':'none',
        visibility: open?'visible':'hidden',
        // alignItems:'center',
        padding:0, maxWidth: '100vw', 
    }}
    // slots={{ backdrop: StyledBackdrop }}
    >
    <div style={{position:'absolute',zIndex: open?999999999:0, display:'flex',
    maxWidth: '100%',maxHeight: '100%', width:'100%', height:'100%'}}>
        <div className='hover-shake' style={compStyles} onClick={(e)=>{
            handleClose()
            // updateAppData({...appData,isAudioOpen})
            }}>
                {isFullView && <img src={closeIcon} width='100%'/>}
                {/* {!isAudioOpen && <img src={musicIcon} width='100%'/>} */}
            </div>
        <Container sx={{ 
            display:'flex',
            position: 'relative',
            maxWidth: '350px', 
            width:'100%',
            height: '100%',
            backgroundColor:'#fff',maxHeight:'90%',
            // zIndex: open?999999999:0,
             overflow:'hidden', 
             overflowY:'scroll', borderRadius:'10px',
           margin: 'auto', padding:'0.5rem',
           border: '3px solid #000', boxShadow:'0px 0px 10px 5px rgba(0,0,0,0.5)'
           }}>
        
          {/* {modalContent} */}
          <Box component={'img'}
          src={fullSrc}

          width='100%'
          height='auto'
          margin='auto'
          >

          </Box>
        </Container>
        </div>
      </Modal>
  )
  //   </div>
}

export default FullViewComponent

const ModalContent = styled('div')(
  ({ theme }) => css`
    font-family: 'IBM Plex Sans', sans-serif;
    font-weight: 500;
    text-align: start;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    overflow: hidden;
    background-color:  '#fff'};
    border-radius: 8px;
    border: 1px solid rgba( 0,0,0,0.5);
    box-shadow: 0 4px 12px rgba( 0,0,0,0.5)
    padding: 24px;
    color: rgba( 0,0,0,0.5);

    & .modal-title {
      margin: 0;
      line-height: 1.5rem;
      margin-bottom: 8px;
    }

    & .modal-description {
      margin: 0;
      line-height: 1.5rem;
      font-weight: 400;
      color: rgba( 0,0,0,0.5);
      margin-bottom: 4px;
    }
  `,
);