import React,{useEffect, useState, useRef, useContext} from 'react'

import { AppContext } from '../context/AppProvider';


const MusicCard = (props) => {
    const {isFullView, setIsFullView ,fullSrc, setIsFullSrc } = useContext(AppContext)

  const p = props.album
  return (
    <div style={{display:'flex',color:"#fff", gap:'0.5rem', width:'auto',maxHeight:'320px'}}>
      <div style={{ borderRadius:'10px',display:'flex', jusstifyContent:'center',
        overflow:'hidden',width:'auto',height:'height',textAlign:'center',
        flexDirection:'column',alignItems:'center',
        // padding:'0.5rem',
        border:'2px solid #fff', boxShadow:'0px 0px 10px 5px rgba(255,255,255,0.5)'
        }}>
          <img src={p?.cover_pic} onClick={(e)=>{
              console.log(e.target.src)
              setIsFullSrc(e.target.src.toString())
              setIsFullView(!isFullView)
          }}/>
          <div style={{position:'absolute',bottom:'-1.5rem', color:'#000', background: 'rgb(255,255,255)',
          background: `linear-gradient(0deg, rgba(255,255,255,1) 0%, 
          rgba(255,255,255,1) 48%, rgba(255,255,255,0) 94%)`,height:'5rem',
              width:'100%', borderRadius:'0 0 5px 5px'}}>

            <span style={{position:'absolute',bottom:'0.5rem',left:'50%',transform:'translate(-50%,0)', color:'#000',
              width:'100%'}} >{props.index + 1} - {p?.tracks[props.index]}</span>
          </div>
        </div>
      <div>
          {/* { p?.name} */}
      </div>   
  </div>
  )
}

export default MusicCard